<template>
  <v-container id="users-list" fluid tag="section">
    <v-layout row wrap>
      <v-col cols="12">
        <!-- <base-material-card color="primary" outlined> -->
        <v-app-bar flat color="white">
          <v-spacer></v-spacer>
          <!-- <curso></curso> -->
        </v-app-bar>
        <v-app-bar flat color="white">
          <v-text-field
            flat
            solo
            prepend-icon="mdi-magnify"
            placeholder="Digite algo"
            v-model="search"
            hide-details
            class=""
          ></v-text-field>
          <v-btn icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
        <!-- <v-card-text class="pa-0"> -->

        <v-data-table
          flat
          outlined
          no-results-text="Nenhum resultado encontrado"
          :headers="complex.headers"
          :search="search"
          :items="items"
          :footer-props="{
            'items-per-page-options': [
              10,
              25,
              50,
              { text: 'Todos', value: -1 },
            ],
          }"
          class="elevation-1"
          item-key="name"
          show-select
          v-model="complex.selected"
        >
          <template slot="item" slot-scope="props">
            <tr :class="{ 'grey--text': props.item.disabled }">
              <td>
                <v-checkbox
                  primary
                  hide-details
                  v-model="props.selected"
                ></v-checkbox>
              </td>
              <td>{{ props.item.displayName }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.phoneNumber }}</td>
              <td>{{ getIsAdmin(props.item) }}</td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
        <!-- </v-card-text> -->
        <!-- </base-material-card> -->
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import toastr from "toastr";
// import {admin} from "firebase-admin";
import { db } from "@/db";

// Utilities
import { mapState } from "vuex";
export default {
  components: {
    Curso: () => import("@/views/dashboard/components/core/Curso"),
    MatriculaLote: () => import("@/views/dashboard/components/core/MatriculaLote")
  },
  firebase: {
    items: db.ref("/cursos/"),
  },
  firebase() {
    let ref = "/cursos/" + this.$route.params.curso + "/matriculados";
    // let url = "/cursos/" + this.$route.params.curso + "/aulas/" + this.$route.params.id;
    // let ref2 = db.ref(url)
    // let ementa = "/cursos/" + this.$route.params.curso + "/ementa/";
    return {
      items: db.ref(ref),
      //   aula: ref2,
      //   ementa: db.ref(ementa),
    };
  },
  data() {
    return {
      dialog: false,
      search: "",
      items: [],
      complex: {
        selected: [],
        headers: [
          {
            text: "Nome",
            value: "displayName",
          },
          {
            text: "E-mail",
            value: "email",
          },
          {
            text: "Telefone",
            value: "phoneNumber",
          },
          {
            text: "Função",
            value: "isAdmin",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    editar(user) {
      this.userEdit = user;
      this.dialogUser = true;
    },
    resetPassword(user) {
      toastr.info(
        "Solicitando redefinição de senha para: " +
          user.displayName +
          ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/sendPasswordResetLink/" + user.email)
        .then((response) => {
          toastr.success(
            "E-mail com redefinição de senha para o usuário: " +
              user.displayName +
              " enviado com sucesso!",
            "MCS Assessoria e Treinamentos"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
    desativar(item) {
      toastr.info(
        "Desativando o curso: " + item.titulo + ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );

    },
    ativar(user) {
      toastr.info(
        "Ativando o cadastro de: " + user.displayName + ". Aguarde ...",
        "MCS Assessoria e Treinamentos"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/enableUser/" + user.uid)
        .then((response) => {
          toastr.success(
            "Usuário: " + user.displayName + " ativado com sucesso!",
            "MCS Assessoria e Treinamentos"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "MCS Assessoria e Treinamentos"
          );
        });
    },
    getNumMatriculados(curso){
      if(curso && curso.matriculados){
        return Object.keys(curso.matriculados).length
      }else{
        return 0
      }
    },
    getIsAdmin(item){
      let retorno= "Aluno";
      console.log(item)
      if(item.isAdmin){
        retorno = "Administrador";
      }
      return retorno;
    }
  },
  computed: {
    ...mapState(["user"]),
    userEdit: {
      get() {
        return this.$store.state.userEdit;
      },
      set(val) {
        this.$store.commit("SET_USER_EDIT", val);
      },
    },
    dialogUser: {
      get() {
        return this.$store.state.dialogUser;
      },
      set(val) {
        this.$store.commit("SET_DIALOG_USER", val);
      },
    },
  },
  created() {
    /*admin
      .auth()
      .getUsers()
      .then((getUsersResult) => {
        console.log("Successfully fetched user data:");
        getUsersResult.users.forEach((userRecord) => {
          console.log(userRecord);
        });

        console.log("Unable to find users corresponding to these identifiers:");
        getUsersResult.notFound.forEach((userIdentifier) => {
          console.log(userIdentifier);
        });
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });*/
  },
  updated() {
    // console.log(this.user);
  },
};
</script>