var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"users-list","fluid":"","tag":"section"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-app-bar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer')],1),_c('v-app-bar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"flat":"","solo":"","prepend-icon":"mdi-magnify","placeholder":"Digite algo","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"flat":"","outlined":"","no-results-text":"Nenhum resultado encontrado","headers":_vm.complex.headers,"search":_vm.search,"items":_vm.items,"footer-props":{
          'items-per-page-options': [
            10,
            25,
            50,
            { text: 'Todos', value: -1 },
          ],
        },"item-key":"name","show-select":""},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:{ 'grey--text': props.item.disabled }},[_c('td',[_c('v-checkbox',{attrs:{"primary":"","hide-details":""},model:{value:(props.selected),callback:function ($$v) {_vm.$set(props, "selected", $$v)},expression:"props.selected"}})],1),_c('td',[_vm._v(_vm._s(props.item.displayName))]),_c('td',[_vm._v(_vm._s(props.item.email))]),_c('td',[_vm._v(_vm._s(props.item.phoneNumber))]),_c('td',[_vm._v(_vm._s(_vm.getIsAdmin(props.item)))]),_c('td')])]}}]),model:{value:(_vm.complex.selected),callback:function ($$v) {_vm.$set(_vm.complex, "selected", $$v)},expression:"complex.selected"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }